import React, { useState } from 'react'
import css from './Listing.module.css'
import { Link } from 'react-scroll';
import classNames from 'classnames';

export default function ListingNavigation(props) {
  const { tabs } = props;
  const [selectedTab, setSelectedTab] = useState('advisorInformation')

  return (
    <ul className={css.listingNavigation}>
      {tabs.map((t, i) => {
        const { tab, link } = t;
        return (
          <li key={i}>
            <Link to={link} smooth={true} offset={-150} duration={50} onClick={() => setSelectedTab(link)} className={classNames(css.tab, selectedTab === link && css.selectedTab)}>
              {tab}
            </Link>
          </li>
        );
      })}
    </ul>
  );
}
