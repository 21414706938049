import React from 'react'
import { H4, H6 } from '../../../components'
import { ensureCurrentUser, ensureUser } from '../../../util/data';
import css from './Listing.module.css'
import Collapsible from 'react-collapsible';

export function AboutInformation(props) {
    const { listing, intl } = props;

    const user = listing.author;
    const userIsCurrentUser = user && user.type === 'currentUser';
    const ensuredUser = userIsCurrentUser ? ensureCurrentUser(user) : ensureUser(user);

    const description = listing.attributes.description;

    return (
        <div className={css.informationContainer}>
            <p className={css.tagLinesName}>
                {description}
            </p>
        </div>
    )
}

export function QuestionAndAnswer(props) {
    const { listing, intl } = props;

    const user = listing.author;
    const userIsCurrentUser = user && user.type === 'currentUser';
    const ensuredUser = userIsCurrentUser ? ensureCurrentUser(user) : ensureUser(user);

    const { questions = [] } = listing.attributes.publicData;

    return (
        <div className={css.informationContainer}>
            <div className={css.faqContent}>
                {questions.map((st, index) => {
                    return <div className={css.faqText}>
                        <Collapsible trigger={`${intl.formatMessage({ id: `EditListingDetailsForm.question${index + 1}` })}`}>
                            {st}
                        </Collapsible>
                        {/* <ul>
                        <li>
                       <b> Question : {intl.formatMessage({ id: `EditListingDetailsForm.question${index+1}` })}</b>
                        </li>
                        <li className={css.answer}>
                        Answer : { st}
                        </li>
                       </ul> */}
                    </div>
                })}
            </div>
        </div>
    )
}