import React from 'react'
import ListingNavigation from './ListingNavigation'
import { Element } from 'react-scroll';


import css from './Listing.module.css'
import { H1, H3, H4, H6 } from '../../../components';
import { ensureCurrentUser, ensureListing, ensureUser } from '../../../util/data';
import SectionReviews from '../SectionReviews';
import {AboutInformation,QuestionAndAnswer} from './AboutInformation';
import SectionGallery from '../SectionGallery';

const ABOUTINFORMATIONTABID = 'about';
const PHOTOSTABID = 'photos';
const REVIEWTABID = 'review';
const QATABID = "Q&A's";
const SERVICESTABID = 'services'

const tabs = [
    {tab:"About",link:ABOUTINFORMATIONTABID},
    {tab:"Photos",link:PHOTOSTABID},
    {tab:"Review",link:REVIEWTABID},
    {tab:"Q&A's",link:QATABID},
    {tab:"Services",link:SERVICESTABID}
]

export default function Listing(props) {
    const { intl, listing,config, reviews, fetchReviewsError,params } = props;


    const ensuredListing = ensureListing(listing); 
    const user = ensuredListing.author;
    const userIsCurrentUser = user && user.type === 'currentUser';
    const ensuredUser = userIsCurrentUser ? ensureCurrentUser(user) : ensureUser(user);

    const { displayName } = ensuredUser.attributes.profile;
    const {website,category}= listing && listing.attributes && listing.attributes.publicData

    return (
        listing && listing.id ? 
        <div className={css.root}>
            <div className={css.listingHeader}>
                <H4>{displayName}</H4>
            </div>

            {/* Navigation */}
            <ListingNavigation tabs={tabs} />

            {/* About */}
            <Element name={ABOUTINFORMATIONTABID}>
            <h3 className={css.title}>About</h3>
                <AboutInformation listing={ensuredListing} intl={intl} />
            </Element>

            {/* photos */}
            <Element name={PHOTOSTABID}>
            <h3 className={css.title}>Photos</h3>
            <SectionGallery
                listing={listing}
                variantPrefix={config.layout.listingImage.variantPrefix}
              />
            </Element>

             {/* Reviews Section */}
             <Element name={REVIEWTABID}>
             <h3 className={css.title}>Review</h3>
                <SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} params={params}/>
            
            </Element>

            {/* Q&A's */}
            <Element name={QATABID}>
            <h3 className={css.title}>Q&A's</h3>  
            <QuestionAndAnswer listing={ensuredListing} intl={intl} />
            </Element>

            {/* Services */}
            <Element name={SERVICESTABID}>
            <h3 className={css.title}>Services</h3>
                <div>
                    Service Provided - {category?.join("," + " ")}
                </div>
                {website && <div>
                    link - {website}
                </div>}
            </Element>
        </div> 
        : null
    )
}
